import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'layout',
        component: () => import('@/views/index.vue'),
    },
    {
        path: '/visit',
        name: 'visit',
        component: () => import('@/views/home.vue'),
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/profile.vue'),
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/views/about.vue'),
    },
    {
        path: '/artifact/:id',
        name: 'artifact-detail',
        component: () => import('@/views/artifact/detail.vue'),
        children: [
            {
                path: '/artifact/:id/360',
                name: 'artifact-360',
                component: () => import('@/views/artifact/360.vue'),
            },
            {
                path: '/artifact/:id/ar',
                name: 'artifact-ar',
                component: () => import('@/views/artifact/ar.vue'),
            },
            {
                path: '/artifact/:id/art',
                name: 'artifact-art',
                component: () => import('@/views/artifact/art.vue'),
            },
            {
                path: '/artifact/:id/fill',
                name: 'artifact-fill',
                component: () => import('@/views/artifact/fill.vue'),
            },
            {
                path: '/artifact/:id/hn',
                name: 'artifact-hn',
                component: () => import('@/views/artifact/hn.vue'),
            }
        ]
    },

    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/views/404.vue'),
        meta: {
            title: 'Not Found',
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BASE_URL),
    routes
})

export default router
