<template>
  <router-view/>
</template>

<style lang="scss">
* {
  font-family: 'Open Sans', sans-serif;
}
button {
  &:focus {
    box-shadow: none !important;
  }
}
.px-50 {
  padding-left: 150px;
  padding-right: 150px;
}
.fs-18px {
  font-size: 18px;
}
.fw-300 {
  font-weight: 300;
}
.fw-600 {
  font-weight: 600;
}
.scrollbar {
  scrollbar-color: #42210b transparent;
  &::-webkit-scrollbar {
    width: 5px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: linear-gradient(-180deg, rgba(203,159,118,1) 0%, rgba(66,33,11,1) 100%);
    border-radius: 10px;
  }
}
</style>
